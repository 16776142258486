import React from "react";
import { Table } from "hooks/useQuery/local-query";
import { FaFileExcelO } from "react-icons/lib/fa";
import { Spinner } from "components/ui";
import ComplianceCoachScorecardTableRow from "./compliance-coach-scorecard-table-row";
import PropTypes from "prop-types";
import { Tooltip } from "components/ui/tool-tip/tooltip";

const ComplianceCoachScorecardOverallDisplay = ({
  data,
  isFetching,
  openModal,
  exportCoaches
}) => {
  const columns = [
    {
      header: "Name",
      field: "CoachName"
    },
    {
      header: <Tooltip id={`single-stat-tooltip`} message={'#of compliant patients + #of non-compliant patients + #of in progress patients'}><div style={{color:'black'}}># Of Patients</div></Tooltip>,
      field: "TotalPatientsCount"
    },
    {
      header: "# of Compliant Patients",
      field: "CompliantPatientsCount"
    },
    {
      header: "# of Non-Compliant Patients",
      field: "NonCompliantPatientsCount"
    },
    {
      header: "# of Patients In Progress",
      field: "CompliantNullPatientsCount"
    },
    {
      header: "# of Resupply Patients",
      field: "ResupplyPatientsCount"
    },
    {
      header: "# of Patients Ordering",
      field: "OrderingPatientsCount"
    },
    {
      header: "Compliance %",
      field: "CompliancePercentage"
    },
    {
      header: "Average Contacts",
      field: "AverageContactsCount"
    },
    {
      header: "Average Days",
      field: "AverageComplianceDaysCount"
    }
  ];

  return (
    <div style={{ margin: "20px 10px 10px 10px" }}>
      {data && !isFetching && (
        <Table
          containerClass="query-table "
          rows={data}
          query={() => {}}
          queryDispatcher={() => {}}
          TableRow={props => (
            <ComplianceCoachScorecardTableRow
              data={props}
              openModal={openModal}
              origin="Overall"
            />
          )}
          rowKey="CoachId"
          columns={columns}
          controls={
            <div className="query-table-controls">
              <h2
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "hsla(0, 0%, 100%, 0.6)"
                }}
              >
                Overall data
              </h2>
              <span
                className="icon-button"
                style={{
                  marginLeft: "20px",
                  justifyItems: "right",
                  alignItems: "flex-end",
                  cursor: "pointer"
                }}
                onClick={() => exportCoaches("overall")}
              >
                <FaFileExcelO size={20} />
              </span>
            </div>
          }
        />
      )}
      {isFetching && <Spinner />}
    </div>
  );
};

ComplianceCoachScorecardOverallDisplay.propTypes = {
  data: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  exportCoaches: PropTypes.func.isRequired
};

export default ComplianceCoachScorecardOverallDisplay;
